import moment from "moment";
import { Helmet } from "react-helmet";
import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from "react-router-dom";
import CustomAppBar from "./components/CustomAppBar";

const LoginPage = lazy(() => import("./pages/auth/login"));
const Dashboard = lazy(() => import("./pages/cockpit/Dashboard"));
const Signup = lazy(() => import("./pages/auth/signup"));
const NotAuth = lazy(() => import("./pages/NotAuth/NotAuth"));
const PaymentsBank = lazy(() => import("./pages/paymentsV2/indexV2"));
const ProfitabilityV4 = lazy(() => import("./pages/profitv2/indexV4"));
const ReviewsV5 = lazy(() => import("./pages/ReviewV5/index"));
const StorageLocations_V2 = lazy(() => import("./pages/inventory/storageLocations/indexV2"));
const AllExport = lazy(() => import("./pages/ModuleExport"));
const Inventorytracker_V3 = lazy(() => import("./pages/inventory/InventoryTracker/inventorytracker_V3"));
const SyncDetails = lazy(() => import("./pages/adminSyncDetails/adminSyncDetails.js"));
const InventoryIndex = lazy(() => import("./pages/inventory/inventoryIndex.js"));
const CustomerDetails = lazy(() => import("./pages/AdminDashboard/adminDashboard.js"));
const AccountSettings = lazy(() => import("./userSettings/UserSettings.js"));
const ChangeLog = lazy(() => import("./pages/changeLogs/index.js"));
const ReturnsInsightsV2 = lazy(() => import("./pages/ReturnsInsightsV2"));

const ReverseLookup = lazy(() => import("./pages/reverseLookup"));
const QrCodeScanner = lazy(() => import("./pages/QRCodeScanner/qrCodeScanner.js"));
const ForgotPassword = lazy(() => import("./pages/auth/forgot"));
const Otp = lazy(() => import("./pages/auth/otp"));
const ChangePassword = lazy(() => import("./pages/auth/changePassword"));
const CreateCampaign = lazy(() => import("./pages/ReviewV5/Campaigns/CreateCampaign"));
const EditCampaign = lazy(() => import("./pages/ReviewV5/Campaigns/EditCampaign"));
const PageNotFound = lazy(() => import("./pages/PageNotFound/pageNotFound"));
const KeywordIndex = lazy(() => import("./pages/keywordTrack"));
const BuyboxSummary = lazy(() => import("./pages/BuyboxSummary/index"));
const NewDashboard = lazy(() => import("./pages/newcustomer/newcustomer"));
const MasterSKU = lazy(() => import("./pages/MasterSku"));
const PriceTracker = lazy(() => import("./pages/PriceTracker/index"));
const SliderPricing = lazy(() => import("./pages/NewPricing/Index"));



const AuthRoute = () => (
  localStorage.getItem("token") ? (
    userPermissions() ?
      !renewplanFunction() ? (
        <Outlet />
      ) : (
        <Navigate to="/account-settings" />
      )
      : <Navigate to='/not-auth' />
  ) : (
    <Navigate to="/login" />
  )
);

const userPermissions = () => {
  let dataArray = JSON.parse(localStorage.getItem('userPermission'))
  let pathName = (window.location.pathname.replace(/[^\w\s]/gi, '') + '_read').toUpperCase()
  if (dataArray.includes(pathName))
    return true
}

const renewplanFunction = () => {
  let days = localStorage.getItem("subscriptionDays");
  var start = new Date();
  var end = moment(days, "YYYY-MM-DD");
  const subscriptiondays = Math.round((end - start) / 1000 / 60 / 60 / 24) + 2;
  if (subscriptiondays <= 0) {
    return true;
  }
  return false;
};
const RoutesPage = (props) => {
  return (
    <Router {...props}>
      <Suspense fallback={<>
        <Helmet>
          <title>{"Forcesight"}</title>
        </Helmet>
        <CustomAppBar />
      </>}>
        <Routes>
          <Route element={<AuthRoute />}>
            <Route  path="/dashboard" element={<Dashboard />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/payment-tracker" element={<PaymentsBank />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/profitability" element={<ProfitabilityV4 />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/reviews" element={<ReviewsV5 />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/sales-defence" element={<BuyboxSummary />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/storage-locations" element={<StorageLocations_V2 />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/admin-dashboard" element={<CustomerDetails />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/downloads" element={<AllExport />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/inventory-tracker" element={<Inventorytracker_V3 />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/sync-details" element={<SyncDetails />} />
          </Route>
          <Route  path="/" element={<LoginPage />} />
          <Route  path="/login" element={<LoginPage />} />
          <Route  path="/register" element={<Signup />} />
          <Route  path="/keywords-tracker" element={<KeywordIndex />} />
          <Route  path="/inventory" element={<InventoryIndex />} />
          <Route  path="/account-settings/*" element={<AccountSettings />} />
          <Route  path="/history" element={<ChangeLog />} />
          <Route  path="/return-insights" element={<ReturnsInsightsV2 />} />
          {/* <Route  path="/return-insightsv2" element={<ReturnsInsightsV2 />} /> */}
          <Route  path="/lookup" element={<ReverseLookup />} />
          <Route  path="/barcode-scanner" element={<QrCodeScanner />} />
          <Route  path="/forgot-password" element={<ForgotPassword />} />
          <Route  path="/enter-otp" element={<Otp {...props} />} />
          <Route  path="/change-password" element={<ChangePassword {...props} />} />
          <Route  path="/create-campaigns" element={<CreateCampaign />} />
          <Route  path="/edit-campaigns/:id" element={<EditCampaign />} />
          {/* <Route  path="/keywords-summary" element={<KeywordIndex />} /> */}
          <Route  path="/price-tracker" element={<PriceTracker />} />
          <Route  path="/review-insights" element={<ReviewsV5 />} />
          <Route  path="/buybox-summary" element={<BuyboxSummary />} />
          <Route  path="/new-dashboard" element={<NewDashboard />} />
          <Route  path="/master-sku" element={<MasterSKU />} />
          <Route  path="/not-auth" element={<NotAuth />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/sliderpricing" element={<SliderPricing />}/>
        </Routes>
      </Suspense>
    </Router>
  );
};
export default RoutesPage;
