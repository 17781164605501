import { addDays } from "date-fns";
import moment from "moment";

const filterState = {
	marketplace: [
		{ name: "Amazon-India", id: "amazon-india", checked: true },
		{ name: "Flipkart", id: "flipkart", checked: true },
		{ name: "Meesho", id: "meesho", checked: true },
		{ name: "Myntra", id: "myntra", checked: true },
		{ name: "Shopify", id: "shopify", checked: true }

	],
	dateFilter: null,
	startDate:    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
	endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
	reviewStatus: 0,
};

export default function filterReducer(state = filterState, action) {
	switch (action.type) {
		case "filter/updateMarketplace": {
			return {
				...state,
				marketplace: action.payload.marketplace,
			};
		}
		case "filter/updateDateFilter": {
			return {
				...state,
				dateFilter: action.payload.dateFilter,
				startDate: action.payload.startDate,
				endDate: action.payload.endDate,
			};
		}
		case "filter/updateReviewFilter": {
			return {
				...state,
				reviewStatus: action.payload.reviewStatus,
			};
		}
		default: {
			return state;
		}
	}
}
