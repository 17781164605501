import { ThemeProvider } from "@material-ui/core/styles";
import "antd/dist/antd.css";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import IdleTimerContainer from "./components/SessionTimeOut/sessionTimeOut";
import { TableContext } from "./context/TableContent";
import { UserContext } from "./context/UserContext";
import "./axios/index.js";
import "./index.css";
import RoutesPage from "./Routes";
import store from "./store";
import { theme } from "./utils/Themes";

const App = () => {
  const [tableRows, setTableRows] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [company, setCompany] = React.useState({});

  return (
      <ThemeProvider theme={theme}>
          <Provider store={store}>
            <TableContext.Provider
              value={{
                rows: tableRows,
                setRows: setTableRows,
              }}
            >
              <UserContext.Provider
                value={{
                  user,
                  setUser,
                  company,
                  setCompany,
                }}
              >
                <RoutesPage />
                <IdleTimerContainer />
              </UserContext.Provider>
            </TableContext.Provider>
          </Provider>
      </ThemeProvider>
  );
};
ReactDOM.render(<App />, document.getElementById("root"));
