import { combineReducers } from "redux";
import accountReducer from "./reducers/accountReducer.js";
import filterReducer from "./reducers/filterReducer.js";

const rootReducer = combineReducers({
  account: accountReducer,
  filter: filterReducer,
});

export default rootReducer;
