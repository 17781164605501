import React, { useRef } from "react";
import IdleTimer from "react-idle-timer";

const IdleTimerContainer = (props) => {
  const idleTimerRef = useRef(null);
  const onIdle = () => {
    // notification.destroy();
    localStorage.removeItem("token");
    window.open(
      `${process.env.REACT_APP_API_LOGIN_PAGE}?redirect_reason=auth_failure`,
      "_self"
    );

    // notification.open({
    //     message: 'Notification',
    //     duration: 0,
    //     description:
    //       'Due to Inactivity .We have closed the session.',
    //     onClick: () => {
    //       console.log('Notification Clicked!');
    //     },
    //   });
  };
  return (
    <div>
      <IdleTimer ref={idleTimerRef} timeout={59 * 1000 * 60} onIdle={onIdle} />
    </div>
  );
};

export default IdleTimerContainer;
