import { Disclosure } from "@headlessui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from ".././images/Forcesight_Logo.svg";
import {
  Menu,
  MenuItem,
  MenuButton,
  MenuDivider,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import TuneRoundedIcon from '@material-ui/icons/TuneRounded';
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import MeetingRoomRoundedIcon from '@material-ui/icons/MeetingRoomRounded';

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

function CustomAppBar(props) {
  const history = useNavigate()
  const data = JSON?.parse(localStorage.getItem("cmpy_info")) || {};
  // const customerEmail = JSON.parse(localStorage.getItem("user_info"));

  // const [anchorEl, setAnchorEl] = React.useState(null);
  const handleLogoutClick = (e) => {
    if (!localStorage.getItem("fs_token")) {
      localStorage.clear();
      history("/login");
    } else {
      let admin_token = localStorage.getItem("fs_token");
      let admin_refresh_token = localStorage.getItem("fs_refresh_token");
      let user_info = localStorage.getItem("fs_user_info");
      let cmpy_info = localStorage.getItem("fs_cmpy_info");
      localStorage.setItem("token", admin_token);
      localStorage.setItem("refresh_token", admin_refresh_token);
      localStorage.setItem("user_info", user_info);
      localStorage.setItem("cmpy_info", cmpy_info);
      localStorage.removeItem("fs_user_info");
      localStorage.removeItem("fs_cmpy_info");
      localStorage.removeItem("fs_token");
      localStorage.removeItem("fs_refresh_token");
      localStorage.removeItem("subscriptionDays");
      history("/admin-dashboard");
    }
  };
  const handleDashboardClick = (e) => {
    history("/dashboard");
  };

  return (
    <Disclosure
      as="nav"
      // style={{ backgroundColor: "#003f45" }}
      className="sticky top-0 z-10 bk-nav bg-gray-800"
    >
      {({ open }) => (
        <>
          <div className="max-w-full mx-auto px-4 tablet:px-2">
            <div className="relative flex items-center justify-between">
              {/* Menu button*/}
              <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 font-bold hover:text-blue-600 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <CloseRoundedIcon
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                  ) : (
                    <MenuRoundedIcon
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>
              {/* Menu button*/}
              <div className="flex-1 flex items-center justify-center md:items-stretch md:justify-between">
                <div
                  className="flex-shrink-0 flex items-center"
                  onClick={handleDashboardClick}
                >
                  <Link to="/dashboard">
                    <img
                      className="block lg:hidden h-8 w-auto cursor-pointer"
                      src={logo}
                      alt="Workflow"
                    />
                  </Link>
                  <Link to="/dashboard">
                    <img
                      className="hidden lg:block h-8 w-auto cursor-pointer"
                      src={logo}
                      alt="Workflow"
                    />
                  </Link>
                </div>
                {/* ~~~Desktop Start~~~ */}
                <div className="hidden md:block md:ml-6">
                  <div className="flex space-x-4">
                    <div className="hidden sm:block sm:ml-6">
                      <div className="flex items-center justify-center">
                      <Link to="/profitability">
                          <a class="flex items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                            Profit
                          </a>
                        </Link>
                        <Link to="/payment-tracker">
                          <a class="flex items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                            Payments
                          </a>
                        </Link>
                        <Link to="/return-insights">
                          <a class="flex items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                            Returns
                          </a>
                        </Link>
                        <Link to="/reviews">
                          <a class="flex items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                            Feedback
                          </a>
                        </Link>
                        <Link to="/price-tracker">
                          <a class="flex items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                            Pricing
                          </a>
                        </Link>
                        <Link to="/sales-defence">
                          <a class="flex items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                            BuyBox
                          </a>
                        </Link>
                        <Link to="/keywords-Tracker">
                          <a class="flex items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                            Keywords
                          </a>
                        </Link>
                      
                        {/* <Menu menuButton={
                          ({ open }) =>
                            <MenuButton className="flex items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Keywords(Beta)
                              {open ? < /> : </>}
                            </MenuButton>}>
                          <Link to="/keyword-Tracker">
                            <MenuItem >
                              Keywords Tracker
                            </MenuItem>
                          </Link>
                          <Link to="/url-rotator">
                            <MenuItem >
                              Url Rotator
                            </MenuItem>
                          </Link>
                        </Menu> */}

                        {/* <Menu
                          menuButton={({ open }) => (
                            <MenuButton className="flex items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                              Workflow Manager
                              {open ? (
                                < />
                              ) : (
                                < />
                              )}
                            </MenuButton>
                          )}
                        >
                          <SubMenu label="Inventory">
                            <Link to="/inventory-tracker">
                              <MenuItem>Inventory Tracker</MenuItem>
                            </Link>
                          </SubMenu>
                          <Link to="/orders-tracker">
                            <MenuItem>Orders</MenuItem>
                          </Link>
                        </Menu> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* ~~~Desktop End~~~ */}
              </div>

              {/* Account Start */}
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0">
                <Menu
                  menuButton={
                    <MenuButton className="flex items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                      {" "}
                      <AccountCircleRoundedIcon />
                    </MenuButton>
                  }
                >
                  <MenuItem class="text-base font-bold text-primary text-center">{data?.org_name}</MenuItem>
                  <MenuDivider />

                  <Link to="/account-settings">
                    <MenuItem className="flex gap-3">
                      <SettingsRoundedIcon />
                      Settings{" "}
                    </MenuItem>
                  </Link>
                  <Link to="/master-sku">
                    <MenuItem className="flex gap-3">
                      <TuneRoundedIcon />
                      Product-Configuration
                    </MenuItem>
                  </Link>
                  <Link to="/history">
                    <MenuItem className="flex gap-3">
                      <BorderColorRoundedIcon />
                      Change Log
                    </MenuItem>
                  </Link>
                  <Link to="/downloads">
                    <MenuItem className="flex gap-3">
                      <GetAppRoundedIcon />
                      Downloads
                    </MenuItem>
                  </Link>
                  <MenuItem
                    className="flex gap-3"
                    onClick={() => {
                      handleLogoutClick();
                    }}
                  >
                    <MeetingRoomRoundedIcon />
                    Logout
                  </MenuItem>
                </Menu>
              </div>
              {/* Account End */}
            </div>
          </div>

          {/* ~~~Mobile Start~~~ */}
          {/* <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <a className="flex items-center text-gray-600 hover:bg-gray-200 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium">
                <Link to="/returns-tracker">Returns</Link>
              </a>
            </div>
          </Disclosure.Panel> */}
          {/* ~~~Mobile End~~~ */}
        </>
      )}
    </Disclosure>
  );
}
export default CustomAppBar;
